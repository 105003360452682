<template>
  <div
    :class="[
      'absolute w-full flex justify-between px-10 z-20 mobile:px-6',
      currentTheme,
    ]"
  >
    <div
      class="mt-5 mobile:flex mobile:justify-between mobile:w-full mobile:items-center"
    >
      <router-link to="/"
        ><img class="w-[142px]" :src="logo" alt="logo"
      /></router-link>
      <a
        class="rounded-full w-[35px] h-[35px] bg-white flex items-center justify-center p-2 pc:hidden"
        href="#"
        @click="handleClick"
        ><img class="w-[17px]" :src="menuIcon" alt=""
      /></a>
    </div>
    <div class="mobile:hidden mt-5 flex space-x-10 text-white">
      <router-link to="/">HOME</router-link>
      <a
        target="_blank"
        href="https://item.taobao.com/item.htm?spm=a230r.1.14.3.284c2014rfTeoZ&id=666916121670&ns=1&abbucket=12#detail"
        >PA-01</a
      >
      <a
        target="_blank"
        href="https://item.taobao.com/item.htm?spm=a1z10.1-c-s.w4004-22215022170.4.11af588eHVJbYx&id=606688976194"
        >PCB-01</a
      >
      <a
        target="_blank"
        href="https://item.taobao.com/item.htm?spm=2013.1.w4023-22202655956.16.3686766em8Fyt1&id=667353393682"
        >PA-01F</a
      >
      <!-- <router-link to="#">NEWS</router-link>
      <router-link to="#">产品</router-link>
      <router-link to="#">下载</router-link>
      <router-link to="#">关于我们</router-link> -->
      <!-- <a href="#">资讯</a>
      <a href="#">产品</a>
      <a href="#">下载</a>
      <a href="#">关于我们</a> -->
    </div>
    <div
      :class="[
        'flex-col bg-custom-black absolute w-full top-[100vh] left-0 text-footer-color px-6 text-[26px] transition-[top] ease-in-out duration-1000 opacity-0 flex h-0 overflow-hidden',
        isOpen ? ' !top-0 !opacity-100  !h-screen' : '',
      ]"
    >
      <div class="mt-4 mb-10 flex justify-between w-full items-center">
        <router-link to="/"
          ><img class="w-[142px]" :src="logo" alt="logo"
        /></router-link>
        <a
          class="rounded-full w-[35px] h-[35px] bg-white flex items-center justify-center p-2"
          href="#"
          @click="handleClose"
        >
          <img src="@/assets/mobile/nav_menu.png" alt="" />
        </a>
      </div>

      <router-link class="border-t border-t-[#404040] py-4" to="/"
        >HOME</router-link
      >
      <a
        class="border-t border-t-[#404040] py-4"
        target="_blank"
        href="https://item.taobao.com/item.htm?spm=a230r.1.14.3.284c2014rfTeoZ&id=666916121670&ns=1&abbucket=12#detail"
        >PA-01</a
      >
      <a
        class="border-t border-t-[#404040] py-4"
        target="_blank"
        href="https://item.taobao.com/item.htm?spm=a1z10.1-c-s.w4004-22215022170.4.11af588eHVJbYx&id=606688976194"
        >PCB-01</a
      >
      <a
        class="border-t border-t-[#404040] py-4"
        target="_blank"
        href="https://item.taobao.com/item.htm?spm=2013.1.w4023-22202655956.16.3686766em8Fyt1&id=667353393682"
        >PA-01F</a
      >
      <!-- <router-link to="#">NEWS</router-link>
      <router-link to="#">产品</router-link>
      <router-link to="#">下载</router-link>
      <router-link to="#">关于我们</router-link> -->
      <!-- <a class="border-t border-t-[#404040] py-4" href="#">资讯</a>
      <a class="border-t border-t-[#404040] py-4" href="#">产品</a>
      <a class="border-t border-t-[#404040] py-4" href="#">下载</a>
      <a class="border-t border-t-[#404040] py-4" href="#">关于我们</a> -->
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { computed, ref } from "vue";
import { isMobile } from "@/utils";
export default {
  name: "Nav",
  props: {
    theme: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const dir = isMobile() ? "mobile" : "pc";
    const route = useRoute();
    const currentTheme = computed(() =>
      route.path === "/" ? props.theme : ""
    );

    const isOpen = ref(false);

    const handleClick = () => {
      isOpen.value = true;
      document.body.classList = "overflow-hidden";
    };

    const handleClose = () => {
      isOpen.value = false;
      document.body.classList = "";
    };

    return {
      handleClick,
      handleClose,
      currentTheme,
      isOpen,
      logo: require(`@/assets/${dir}/img_logo.png`),
      menuIcon: require(`@/assets/mobile/nav_menu_pre_${props.theme}.png`),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
