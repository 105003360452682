// PA-01
// PCB-01
// PA-01F
export const theme = "PA-01";
// export const theme = "PCB-01";
// export const theme = "PA-01F";

export const Taobao = {
  "PA-01": {
    link: "https://item.taobao.com/item.htm?spm=a230r.1.14.3.284c2014rfTeoZ&id=666916121670&ns=1&abbucket=12#detail",
    title: "灵感永动机",
  },

  "PCB-01": {
    link: "https://item.taobao.com/item.htm?spm=a1z10.1-c-s.w4004-22215022170.4.11af588eHVJbYx&id=606688976194",
    title: "俄罗斯方块挂饰",
  },
  "PA-01F": {
    link: "https://item.taobao.com/item.htm?spm=2013.1.w4023-22202655956.16.3686766em8Fyt1&id=667353393682",
    title: "灵感永动装置 ",
  },
};

export const taobaoItem = Taobao[theme];
