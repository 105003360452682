<script setup>
import { isMobile } from "@/utils";
import { onMounted, onUnmounted, ref } from "vue";
const dir = isMobile() ? "mobile" : "pc";
const walk1 = ref(null);
const walk2 = ref(null);
const home = ref(null);
let timmer = ref(null);
const isScolling = ref(true);
const walking = () => {
  let num = 0;
  timmer.value = setInterval(() => {
    if (isScolling.value) {
      num -= 2;
      walk1.value.style.transform = `translate3d(${num}px, 0px, 0px)`;
      walk2.value.style.transform = `translate3d(${num}px, 0px, 0px)`;

      if (num < -walk1.value.clientWidth - (isMobile() ? 40 : 100)) {
        num = 0;
      }
    }
  }, 24);
};

const resizer = () => {
  home.value.style.height = window.innerHeight + "px";
};

onMounted(() => {
  walking();
  window.addEventListener("resize", () => {
    resizer();
  });

  if (isMobile()) {
    home.value.style.height = window.innerHeight + "px";
  }
});

onUnmounted(() => {
  window.removeEventListener("resize", resizer);
});

const { theme, taobaoItem, Taobao } = require("../constans");
const banner = require(`@/assets/${dir}/Pic_Banner_${theme}.png`);
const word = require(`@/assets/${dir}/${theme}_word.png`);
const arrow = require(`@/assets/${dir}/${theme}_arrow.png`);

const walking1 = require(`@/assets/${dir}/walkingPA-01.png`);
const walking2 = require(`@/assets/${dir}/walkingPCB-01.png`);
const walking3 = require(`@/assets/${dir}/walkingPA-01F.png`);

const imgFooter = require(`@/assets/${dir}/img_footer.png`);

const handleMouse = () => {
  isScolling.value = false;
};
const handleLeave = () => {
  isScolling.value = true;
};
</script>

<template>
  <div>
    <div
      :class="[
        'home overflow-hidden flex-1 bg flex justify-center items-center min-h-[540px] h-screen mobile:h-[calc(100vh-80px)] mobile:min-h-[auto] mobile:pb-44 relative',
        theme,
      ]"
      ref="home"
    >
      <div class="px-10">
        <img
          class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] mobile:w-[90%]"
          :src="word"
          alt=""
        />
        <img
          class="w-[768px] relative z-10 mobile:pt-10"
          :src="banner"
          alt=""
        />
      </div>
      <div
        class="absolute right-4 bottom-4 z-10 flex items-center flex-col mobile:left-[50%] mobile:translate-x-[-50%] mobile:right-0 mobile:bottom-5"
      >
        <a
          :href="taobaoItem.link"
          target="_blank"
          class="flex items-center justify-center text-[18px] rounded-[28px] pt-2 pb-2 pl-10 pr-10 buy-button mobile:w-[231px]"
          >产品详情
          <i class="rounded-[28px] ml-4 arrow-bg p-4"
            ><img class="w-[24px] relative top-[-4px]" :src="arrow" alt="" /></i
        ></a>
        <div class="mt-4 title">
          {{ taobaoItem.title }}
          {{ theme }}
        </div>
      </div>
    </div>
    <div
      class="bg-custom-black flex flex-nowrap pt-10 gap-x-36 mobile:gap-x-8 pb-10 overflow-hidden"
      @mouseenter="handleMouse"
      @mouseleave="handleLeave"
    >
      <div ref="walk1" class="flex flex-none gap-x-36 mobile:gap-x-8">
        <a class="flex-none" :href="Taobao['PA-01'].link">
          <img class="h-[134px] mobile:h-[38px]" :src="walking1" alt="" />
        </a>
        <a class="flex-none" :href="Taobao['PCB-01'].link">
          <img class="h-[134px] mobile:h-[38px]" :src="walking2" alt="" />
        </a>
        <a class="flex-none" :href="Taobao['PA-01F'].link">
          <img class="h-[134px] mobile:h-[38px]" :src="walking3" alt="" />
        </a>
      </div>
      <div ref="walk2" class="flex flex-none gap-x-36 mobile:gap-x-8">
        <a class="flex-none" :href="Taobao['PA-01'].link">
          <img class="h-[134px] mobile:h-[38px]" :src="walking1" alt="" />
        </a>
        <a class="flex-none" :href="Taobao['PCB-01'].link">
          <img class="h-[134px] mobile:h-[38px]" :src="walking2" alt="" />
        </a>
        <a class="flex-none" :href="Taobao['PA-01F'].link">
          <img class="h-[134px] mobile:h-[38px]" :src="walking3" alt="" />
        </a>
      </div>
    </div>
    <div
      class="bg-custom-black flex mobile:flex-col justify-between mobile:justify-center pr-16 items-center pt-10 pb-10 mobile:pr-0 mobile:pt-0"
    >
      <div>
        <img class="w-[704px] mobile:w-[370px]" :src="imgFooter" alt="" />
      </div>
      <div
        class="text-[46px] mobile:text-[16px] text-footer-color mobile:mt-10 mobile:leading-6"
      >
        <div>小红书：LOWOO ( 2175130942 )</div>
        <div>淘宝：LowooLab ( lowoo.taobao.com )</div>
        <div>抖音：LOWOO</div>
        <div>邮箱：info@lowoo.cc</div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
