<template>
  <div>
    <div
      class="bg-custom-black flex justify-between px-10 border-t border-t-[#404040] pt-10 pb-10 text-footer-color mobile:hidden"
    >
      <div class="flex space-x-5">
        <div class="font-bold">COPYRIGHT 2022-2062 LOWOO</div>
        <!-- <div>京 ICP 备 11017824 号-4</div> -->
      </div>
      <!-- <div class="flex space-x-10">
        <a to="#">商店</a>
        <a to="#">隐私</a>
        <a to="#">条款和条件</a>
        <a to="#">新闻</a>
        <a to="#">联系</a>
      </div> -->
    </div>
    <div class="bg-custom-black pt-10 pb-10 text-footer-color pc:hidden">
      <div class="flex space-x-5 pl-5">
        <!-- <a to="#">商店</a>
        <a to="#">隐私</a>
        <a to="#">条款和条件</a>
        <a to="#">新闻</a>
        <a to="#">联系</a> -->
      </div>
      <div class="flex space-x-5 pl-5 pt-5 mt-5 border-t border-t-[#404040]">
        <div class="font-bold">COPYRIGHT 2022-2062 LOWOO</div>
        <!-- <div>京 ICP 备 11017824 号-4</div> -->
      </div>
    </div>
  </div>
</template>
