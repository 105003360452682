<template>
  <div>
    <Navbar :theme="theme" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footerbar.vue";
import Navbar from "@/components/Navbar.vue";
const { theme } = require("./constans");
export default {
  name: "APP",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      theme,
    };
  },
};
</script>

<style>
#app {
  font-family: lovelo, sans-serif, "Microsoft YaHei", "Heiti SC";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  display: flex;
  flex-direction: column;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
